const getStoredUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return userData;
};

const getStoredToken = () => {
    const storedUserData = getStoredUserData();
    return storedUserData.token || '';
};

const getStoredTree = () => {
    const tree = JSON.parse(localStorage.getItem('tree')) || {};
    return tree;
};

const getStoredTreeId = () => {
    const storedTree = getStoredTree();
    return storedTree.id;
};

const getHeaders = () => {
    return {
        'Authorization': `Bearer ${getStoredToken()}`,
        'Content-Type': 'application/json',
    };
};
const proxy = 'https://cors-anywhere.herokuapp.com/'

export const TOKEN = () => getStoredToken();
export const TREE_ID = () => getStoredTreeId();
export const API_BASE_URL = `https://bogdan-metelsky.ru/v1`;
export const HEADERS = () => getHeaders();