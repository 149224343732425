import axios from 'axios';
import { API_BASE_URL, HEADERS } from '../settings';

const createAcount = async (acountData) => {
    try {

    const response = await axios.put(`${API_BASE_URL}/account/edit`, acountData, {
            headers: HEADERS()
        });

        if (response.status === 200) {
            const responseData = response.data;
            return responseData;
        } else {
            console.error('Error edit user:', response.status);
        }
    } catch (error) {
        console.error('Error edit user:', error);
        throw new Error('Error edit user:', error);
    }
};

export default createAcount;
