import React, { Suspense, lazy, memo } from 'react';
import styles from './App.module.css';
import { Routes, Route } from 'react-router-dom';
import Footer from '../Pages/Footer/Footer';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from './../Components/Loading/Loading';

// Используем lazy для динамической загрузки страниц
const LandingPage = lazy(() => import('../Pages/Home/Lending/LandingPage'));
const LandingNew = lazy(() => import('../Pages/Lending/Lending'));
const SimpleLayout = lazy(() => import('./Routes/SimpleLayout'))
const ChatWidget = lazy(() => import('../Pages/Support/ChatWidget'));

const App = memo(() => {

  return (
      <div className={`${styles.appContainer}`}>
        <ToastContainer />
        <Suspense fallback={<Loading/>}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/landing" element={<LandingNew />} />
              <Route path="/*"element={<SimpleLayout />}/>
            </Routes>
          </Suspense>
      </div>
  );
});

export default App;
